import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { setGrowthDayToCycle, updateGrowthThunk } from "../../store/coopsForUserSlice";
import style from "./style.module.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// import {
//   addParametersToUrl,
//   cleanParametersFromUrl,
//   getUrlParameters,
// } from "../../utils/urlUtils";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
dayjs.extend(utc);

function GrowthDayInput({ fn = () => {}, reloadChart = () => {} }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ days, setDay] = useState([]); 
  const [ dayForUpdate, setDayForUpdate] = useState({}); 
  const [ value, setValue] = useState(0); 
  // const cyclesArray = useSelector((state) => state.coopsForUser.cyclesArray);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);

  const isToday = chosenCycle
    ? chosenCycle.maxGrowthDay === chosenCycle.settedGrowthDay &&
      chosenCycle.active
    : false;

  // const [growthDay, setGrowthDay] = useState("");

  useEffect(() => {
    if (chosenCycle) {
      let days = [];
      for (let i = 1; i <= chosenCycle.planedDays; i++) {
        let day = chosenCycle.active?moment().diff(moment(chosenCycle.startDate), "days"):moment(chosenCycle.endDate).diff(moment(chosenCycle.startDate), "days");
        days.push({
          value: i,
          active: i === day,
          disabled: i > day,
          date: moment(chosenCycle.startDate).add(i - 1, "days").toString()
        });
        if (i === day) {
          setDayForUpdate({
            value: i,
            active: true,
            disabled: false,
            date: moment(chosenCycle.startDate).add(i - 1, "days").toString()
          });
        }
      }
      setDay(days);
    }
  }, [chosenCycle]);

  // useEffect(() => {
  //   const currentDayInUrl = getUrlParameters()["day"];
  //   if (currentDayInUrl) {
  //     setGrowthDay(+currentDayInUrl);
  //     localStorage.setItem("SALUS_GROWTH_DAY", currentDayInUrl);
  //   }
  // }, [window.location.search]);

  // useEffect(() => {
  //   if (chosenCycle) {
  //     setGrowthDay(chosenCycle.settedGrowthDay);
  //     localStorage.setItem("SALUS_GROWTH_DAY", chosenCycle.settedGrowthDay);

  //     if (!isToday) {
  //       addParametersToUrl([`day=${chosenCycle.settedGrowthDay}`]);
  //     } else {
  //       cleanParametersFromUrl();
  //     }
  //   } else {
  //     setGrowthDay("");
  //     localStorage.removeItem("SALUS_GROWTH_DAY")
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chosenCycle]);

  const handleDays = (day) => {
    setDayForUpdate(day);
    setDay((old) => {
      return old.map((i) => {
        if (i.value === day.value) {
          return { ...i, active: true };
        }
        return { ...i, active: false };
      })
    });
  }

  const onSubmit = () => {
    dispatch(
      fn({
        date: dayForUpdate.date,
        value: value,
        coopId: chosenCycle.coopId,
      })
    ).then((res) => {
      reloadChart();
    });
  };

  return (
    <div className={style.dayGrowthContainer}>
      <div className={style.growDayRow}>
        <label>{t("DEATH_RATE.GROWTH_DAY")}</label>
        <div className={style.content}>
            <div className={style.containerContent}>
            {days.map((day)=> (
              <div key={day.value} className={classNames(style.day, {
                [style.active]: day.active,
                [style.disabled]: day.disabled
              })} onClick={() => handleDays(day)}>{day.value}</div>
            ))}
            </div>
        </div>
      </div>
      <div className={style.growDayChikenRow}>
        <input
          type="number"
          className={style.input}
          defaultValue={value}
          min={0}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className={style.confirm} onClick={() => onSubmit()}>
          Confirm
        </div>
      </div>
    </div>
  );
}

export default GrowthDayInput;
