import { useEffect, useState } from "react";
import styles from "./AQIIndex.module.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { api } from "../../../api/api";

function AQIIndex() {
  const { i18n, t } = useTranslation();
  const [isNow, setIsNow] = useState(true);
  const [AQIData, setAQIData] = useState(-1);
  const [PMData, setPm] = useState(1);
  const [loader, setLoader] = useState(true);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  const activeCycle = useSelector((state) => state.coopsForUser.activeCycle);
  const cyclesArray = useSelector((state) => state.coopsForUser.cyclesArray);

  const isToday = chosenCycle
    ? chosenCycle.maxGrowthDay === chosenCycle.settedGrowthDay &&
      chosenCycle.active
    : false;

  useEffect(() => {
      makeRequest(isNow);
  }, [chosenCycle]);

  const getMark = (value = 0) => {
    if (value > 300) return 100;
    if (value < 0) return 0;
    return value / 3;
  };

  const getMarkPopup = (value = 0) => {
    if (value <= 150) return {left: "-24px"};
    if (value > 150) return {right: "-24px"};
  };


  const makeRequest = (isNow = true) => {
    if(!chosenCycle && !cyclesArray.length) {
      setLoader(false);
      setAQIData(-1);
      setPm(1);
      return;
    }
    setLoader(true);
    api.dashboard.getAQIData(
      chosenCycle._id,
      chosenCycle.settedGrowthDay,
      isNow
    ).then(data => {
      setAQIData(data.data.aqi);
      setPm(data.data.pm);
      setLoader(false);
    }).catch(err => {
      setAQIData(1);
      setPm(1);
      setLoader(false);
    });
  };

  const handleClickWeeklyAverage = () => {
    setIsNow(false);
    makeRequest(false);
  };

  const handleClickNow = () => {
    setIsNow(true);
    makeRequest(true);
  };

  const getDescPmLabel = (val) => {
    let res = {
      label: "",
      desc: ""
    }

    if(val <= 50) {
      res.label = "EFFICIENCY.GOOD";
      res.desc = "Air quality is satisfactory, and air pollution poses little or no risk.";
    } else if (val > 50 && val <= 100 ) {
      res.label = "EFFICIENCY.MODERATE";
      res.desc = "Air quality is acceptable. However, there may be a risk for some people, particularly those who are unusually sensitive to air pollution.";
    } else if (val < 100 && val <= 150) {
      res.label = "EFFICIENCY.UNHEALTHYFOR";
      res.desc = "Members of sensitive groups may experience health effects. The general public is less likely to be affected.";
    } else if (val > 150 && val <= 200) {
      res.label = "EFFICIENCY.UNHEALTHY";
      res.desc = "	Some members of the general public may experience health effects; members of sensitive groups may experience more serious health effects.";
    } else if (val > 200 && val <= 300) {
      res.label = "EFFICIENCY.VERYUNHEALTHY";
      res.desc = "Health alert: The risk of health effects is increased for everyone.";
    } else {
      res.label = "EFFICIENCY.HAZARDOUS";
      res.desc = "Health warning of emergency conditions: everyone is more likely to be affected.";
    }

    return res;
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t("EFFICIENCY.AQI_LEVEL")}</h3>
        <div className={styles.buttons}>
          {activeCycle && (
            <>
              <button
                type="button"
                onClick={handleClickNow}
                className={classNames({
                  [styles.pressed]: isNow,
                  [styles.unpressed]: !isNow,
                })}
              >
                {isToday
                  ? t("EFFICIENCY.NOW_BUTTON")
                  : t("EFFICIENCY.HOURS_24")}
              </button>
              <button
                type="button"
                onClick={handleClickWeeklyAverage}
                className={classNames({
                  [styles.pressed]: !isNow,
                  [styles.unpressed]: isNow,
                })}
              >
                {t("EFFICIENCY.AVERAGE_BUTTON_2")}
              </button>
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.content, {
          [styles.rtlDir]: i18n.language === "he",
        })}
      >
        {loader ? (
          <CircularProgress />
        ) : (
          <>
            
            <div className={styles.bar}>
              {AQIData && (
                <div
                  className={styles.tip}
                  style={{ left: `calc(${getMark(AQIData)}% - 2px)` }}
                >
                  <div className={styles.hint}>
                    {t(getDescPmLabel(AQIData).label)}
                    
                  </div>
                  <div className={styles.popup} style={{...getMarkPopup(AQIData)}}>
                      <div className={styles.popupHeader}>
                        <div>
                          AQI Level
                          <b>{AQIData.toFixed(2)}</b>  
                        </div>
                        <div>
                          PM 2.5 (ug/m2)
                          <b>{PMData.toFixed(2)}</b>  
                        </div>
                      </div>
                      <div className={styles.popupContent}>
                        <div>Health Recommendations (For 24hr Exposure)</div>
                        <b>{getDescPmLabel(AQIData).desc}</b>
                      </div>
                    </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AQIIndex;
