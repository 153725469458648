import axios from "axios";
import { logoutUser } from "../store/authSlice.js";
import { createErrorThunk } from "../store/notification.js";

let store;
export const injectStore = (_store) => {
  store = _store;
};
const axiosInstance = axios.create({});
// const API_ENDPOINT = "http://localhost:3002/api";
const API_ENDPOINT = "https://dev-api.sinornis.com/api";
// const API_ENDPOINT = "https://stage-api.sinornis.com/api";


const urlsSkipAuth = [`${API_ENDPOINT}/Account/Login`];

axiosInstance.interceptors.request.use(async (config) => {
  if (urlsSkipAuth.includes(config.url)) {
    return config;
  }

  let accessToken = store.getState().auth.authData.accessToken;
  if (config.url === `${API_ENDPOINT}/Account/Refresh`) {
    accessToken = localStorage.getItem("SALUS_ACCESS_TOKEN");
  } else {
    accessToken = store.getState().auth.authData.accessToken;
  }

  if (accessToken) {
    const Authorization = `${accessToken}`;
    config.headers = {
      Authorization,
    };
  }

  return config;
});

export const makeRequest = (config) => {
  config.url = `${API_ENDPOINT}${config.url}`;
  let accessToken = localStorage.getItem("SALUS_ACCESS_TOKEN");
  
  if (accessToken) {
    const Authorization = `${accessToken}`;
    config.headers = {
      ...config.headers,
      Authorization,
    };
  }

  return axiosInstance(config);
};

axiosInstance.interceptors.response.use(
  (response) => {
    if(response.data?.status === "error") {
      if(response.data?.message === "athn-1" || 
      response.data?.message === "athn-2" || 
      response.data?.message === "athn-3") {
        store.dispatch(logoutUser());
        window.location.reload();  
      } 
      throw response.data?.message;
    }
    return response;
  },
  (error) => {
    try {
      if(error.response?.status === 500) {
        if(error.response?.data?.message) {
          switch (error.response.data.message) {
            case "ua-3":
              store.dispatch(createErrorThunk("Wrong password"));
              break;
            case "ua-2":
              store.dispatch(createErrorThunk("User not exist"));
              break;
            case "ua-1":
              store.dispatch(createErrorThunk("JWT token not valid"));
              break;
            default:
              store.dispatch(createErrorThunk(JSON.stringify(error.response.data.message)));
          }
        } else {
          store.dispatch(createErrorThunk("Error: udefined"));
        }
      }
      if (
        error.response?.status === 401 &&
        !!store.getState().auth.authData.accessToken &&
        error.request.url !== `${API_ENDPOINT}/logout`
      ) {
        store.dispatch(logoutUser());
        store.dispatch(createErrorThunk("Error: session is invalid"));
      }

      if (
        error.response?.status === 400
      ) {
        store.dispatch(createErrorThunk("Error: " + error.response?.data?.message));
      }
      return;
    } catch (err) {
      console.error("Error handling response error:", err);
      store.dispatch(createErrorThunk("An unexpected error occurred"));
    }
    // return Promise.reject(error);
    throw error;
  }
); 
