import styles from "./DialogForEditGrowingCycle.module.scss";
import { Dialog } from "@mui/material";
import { useForm } from "react-hook-form";
import CloseIconButton from "../shared-components/closeIconButton/CloseIconButton";
import CommonButton from "../shared-components/commonButton/CommonButton";
import CommonInput from "../shared-components/commonInput/CommonInput";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect } from "react";

function DialogForEditGrowingCycle({ open, closeEditCycleDialog }) {
  const { t } = useTranslation();
  const choosenCoopId = useSelector(
    (state) => state.coopsForUser.getCoopById.response?._id
  );
  const activeCycle = useSelector((state) => state.coopsForUser.activeCycle);
  
  const defaultValues = {
    name: moment().format("YYYY-MM-DD-hh-mm-ss"),
    chickens: "",
    totalDays: 38,
    currentDay: 1
  };

  useEffect(() => {
    if(activeCycle) {
      setValue("name", activeCycle.name);
      setValue("chickens", activeCycle.chickens);
      setValue("totalDays", activeCycle.totalDays);
      setValue("currentDay", activeCycle.maxGrowthDay);
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCycle])

  const { reset, handleSubmit, control, setValue } = useForm({
    mode: "all",
    defaultValues,
  });

  const onSubmit = (data) => {
    closeEditCycleDialog(choosenCoopId, activeCycle._id, data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => closeEditCycleDialog()}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: ".63rem",
            boxShadow: "0 .19rem .38rem #00000029",
          },
        },
      }}
    >
      <div className={styles.container}>
        <CloseIconButton
          positionStyles={{
            top: "1.13rem",
            right: "1rem",
          }}
          rules={{
            required: true,
            defaultValues: 1,
          }}
          handleClick={closeEditCycleDialog}
        />

        <h2>{t("NEW_GROWINGCYCLE_DIALOG.HEADER")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CommonInput
            labelText={t("NEW_GROWINGCYCLE_DIALOG.CYCLE_NAME")}
            placeholder={t("NEW_GROWINGCYCLE_DIALOG.NAME_PLACEHOLDER")}
            control={control}
            name="name"
            rules={{ 
              required: true
             }}
          />

          

          <div className={styles.durationStack}>
            <CommonInput
              labelText={t("NEW_GROWINGCYCLE_DIALOG.TOTAL_GROWTH_DAY")}
              placeholder={t("NEW_GROWINGCYCLE_DIALOG.NUMBER_PLACEHOLDER")}
              inputType="number"
              control={control}
              name="totalDays"
              rules={{
                required: true,
                min: 1,
              }}
              setValue={setValue}
            />

            <CommonInput
              labelText={t("NEW_GROWINGCYCLE_DIALOG.CHICKENS_NUMBER")}
              placeholder={t("NEW_GROWINGCYCLE_DIALOG.NUMBER_PLACEHOLDER")}
              inputType="number"
              control={control}
              name="chickens"
              rules={{
                required: true,
                min: 1,
              }}
              setValue={setValue}
            />
          </div>

          <CommonButton
            buttonText={t("NEW_GROWINGCYCLE_DIALOG.SAVE_BUTTON")}
            buttonType="submit"
            buttonStyles={{
              backgroundColor: "#0ACF83",
            }}
          />
        </form>
      </div>
    </Dialog>
  );
}

export default DialogForEditGrowingCycle;
