import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  extraReducers,
  fulfilled,
  pending,
  rejected,
} from "../utils/sharedExtraReducers";
// import { getDeathRateForUserByCycleIdThunk } from "./dashboardSlices/deathRateForUserSlice";
// import { getGrowthRateForUserByCycleIdThunk } from "./dashboardSlices/growthRateForUserSlice";
dayjs.extend(utc);

const initialState = {
  getCoopById: {
    response: null,
    status: null,
    error: null,
  },
  startingCycle: {
    status: null,
    error: null,
  },
  stopingCycle: {
    response: null,
    status: null,
    error: null,
  },
  cyclesArray: [],
  chosenCycle: "",
  chosenCoop: "",
  activeCycle: "",
  cycleWithSettedGrowthDay: null,
  farmIdFromLocalStorage: localStorage.getItem("SALUS_FARM_ID"),
  coopIdFromLocalStorage: localStorage.getItem("SALUS_COOP_ID"),
  cycleIdFromLocalStorage: localStorage.getItem("SALUS_CYCLE_ID"),
  growthDayFromLocalStorage: localStorage.getItem("SALUS_GROWTH_DAY"),
};

export const getCoopByIdThunk = createAsyncThunk(
  "coopsForUser/getCoopById",
  async function (
    { chosenCoopId, cycleIdFromLocalStorage, growthDayFromLocalStorage },
    { rejectWithValue, dispatch }
  ) {
    try {
      if(!chosenCoopId) return;
      const response = await api.coops.getCoopById(chosenCoopId);
      const coop = response.data;
      dispatch(addGettedCoopToState(coop));

      const cyclesArray = coop.cycles.length && coop.cycles.map((cycle) => {
        if (cycle.active) {
          const startDate = dayjs(cycle.startDate).utc();
          const now = dayjs().utc();
          const maxGrowthDay = now.diff(startDate, "day");
          let settedGrowthDay = maxGrowthDay;

          if (growthDayFromLocalStorage && cycleIdFromLocalStorage === cycle._id) {
            settedGrowthDay = growthDayFromLocalStorage;
            dispatch(removeLocalStorageDataOfGrothDayFromState(null));
          }

          return { ...cycle, maxGrowthDay, settedGrowthDay };
        } else {
          const startDate = dayjs(cycle.startDate).utc();
          const endDate = dayjs(cycle.endDate).utc();
          const maxGrowthDay = endDate.diff(startDate, "day");
          let settedGrowthDay = maxGrowthDay;

          if (growthDayFromLocalStorage && cycleIdFromLocalStorage === cycle._id) {
            settedGrowthDay = growthDayFromLocalStorage;
            dispatch(removeLocalStorageDataOfGrothDayFromState(null));
          }

          return { ...cycle, maxGrowthDay, settedGrowthDay };
        }
      }) || [];

      dispatch(addCyclesToState(cyclesArray));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const startCycleThunk = createAsyncThunk(
  "coopsForUser/startCycle",
  async function (dataForStartCycle, { rejectWithValue, dispatch }) {
    try {
      const { coopId, ...data } = dataForStartCycle;
      const response = await api.coops.startCycle(coopId, data);

      const newCycle = {
        ...response.data,
        maxGrowthDay: data.currentDay,
        settedGrowthDay: data.currentDay,
      };
      dispatch(addNewCycleToCoop(newCycle));
      dispatch(setActiveCycle(newCycle));
      dispatch(addChosenCycleToState(newCycle));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const editCycleThunk = createAsyncThunk(
  "coopsForUser/startCycle",
  async function (dataForEditCycle, { rejectWithValue, dispatch }) {
    try {
      const { coopId, cycleId, data } = dataForEditCycle;

      const response = await api.coops.editCycle(coopId, cycleId, data);
      const newCycle = {
        ...response.data,
        maxGrowthDay: data.currentDay,
        settedGrowthDay: data.currentDay,
      };
      // dispatch(addNewCycleToCoop(response?.data));
      dispatch(setActiveCycle(newCycle));
      dispatch(addChosenCycleToState(newCycle));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const stopCycleThunk = createAsyncThunk(
  "coopsForUser/stopCycle",
  async function (coopId, { rejectWithValue, dispatch }) {
    try {
      const response = await api.coops.stopCycle(coopId);
      dispatch(replaceChangedCycleInCoop(response.data));
      dispatch(setActiveCycle(""));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateDeathThunk = createAsyncThunk(
  "coopsForUser/updateDeath",
  async function (dataUpdateDeath, { rejectWithValue, dispatch, getState }) {
    try {
      const { coopId, date, value } = dataUpdateDeath;
      await api.coops.updateDeath(coopId, {date, value});
      // dispatch(
      //   getDeathRateForUserByCycleIdThunk({
      //     chosenCycleId: getState().coopsForUser.chosenCycle._id,
      //   })
      // );
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateGrowthThunk = createAsyncThunk(
  "coopsForUser/updateGrowth",
  async function (dataUpdateGrowth, { rejectWithValue, dispatch, getState }) {
    try {
      const { coopId, date, value } = dataUpdateGrowth;
      await api.coops.updateGrowth(coopId, {date, value});
      // dispatch(
        // getGrowthRateForUserByCycleIdThunk({
        //   chosenCycleId: getState().coopsForUser.chosenCycle._id,
        // })
      // );
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const coopsForUserSlice = createSlice({
  name: "coopsForUser",
  initialState,
  reducers: {
    addGettedCoopToState(state, action) {
      if (!action.payload) {
        state.getCoopById.response = null;
        return;
      }
      state.getCoopById.response = action.payload;
    },

    addCyclesToState(state, action) {
      state.cyclesArray = action.payload;
    },

    addChosenCycleToState(state, action) {
      state.chosenCycle = action.payload;
    },

    addChosenCoopsToState(state, action) {
      state.chosenCoop = action.payload;
    },

    addChosenCycleToStateWhenSettedFirstDay(state, action) {
      const { chosenCycleId, cyclesArray, cycleWithSettedGrowthDay } =
        action.payload;
      const newCyclesArray = cyclesArray.map((cycle) => {
        if (cycle._id === cycleWithSettedGrowthDay._id) {
          return {
            ...cycle,
            settedGrowthDay: cycle.maxGrowthDay,
            growthDayUpdated: true,
          };
        }
        return { ...cycle, growthDayUpdated: true };
      });
      const newChosenCycle = newCyclesArray.find(
        (cycle) => cycle._id === chosenCycleId
      );

      state.cyclesArray = newCyclesArray;
      state.chosenCycle = newChosenCycle;
      state.cycleWithSettedGrowthDay = null;
    },

    addChosenCycleToStateOtherCase(state, action) {
      const { chosenCycleId, cyclesArray } = action.payload;

      const newCyclesArray = cyclesArray.map((cycle) => ({
        ...cycle,
        settedGrowthDay: cycle.maxGrowthDay,
        growthDayUpdated: true,
      }));
      const newChosenCycle = newCyclesArray.find(
        (cycle) => cycle._id === chosenCycleId
      );

      state.cyclesArray = newCyclesArray;
      state.chosenCycle = newChosenCycle;
    },

    setActiveCycle(state, action) {
      state.activeCycle = action.payload;
    },
    setActiveCycleCountChicken(state, action) {
      state.activeCycle.chickens = action.payload;
    },
    setGrowthDayToCycle(state, action) {
      const { cyclesArray, chosenCycleId, newValue } = action.payload;
      const newCyclesArray = cyclesArray.map((cycle) => {
        if (cycle._id === chosenCycleId) {
          return {
            ...cycle,
            settedGrowthDay: newValue,
            growthDayUpdated: true,
          };
        } else {
          return { ...cycle, growthDayUpdated: true };
        }
      });
      const newChosenCycle = newCyclesArray.find(
        (cycle) => cycle._id === chosenCycleId
      );
      state.cyclesArray = newCyclesArray;
      state.chosenCycle = newChosenCycle;
    },
    addNewCycleToCoop(state, action) {
      state.getCoopById.response.cycles.push(action.payload);
    },
    replaceChangedCycleInCoop(state, action) {
      const newCycleId = action.payload._id;
      const indexOfOldCycle = state.getCoopById.response.cycles.findIndex(
        (cycle) => cycle._id === newCycleId
      );
      state.getCoopById.response.cycles[indexOfOldCycle] = action.payload;
    },
    removeLocalStorageDataOfFarmIdFromState(state, action) {
      state.farmIdFromLocalStorage = action.payload;
    },
    removeLocalStorageDataOfCoopIdFromState(state, action) {
      state.coopIdFromLocalStorage = action.payload;
    },
    removeLocalStorageDataOfCycleIdFromState(state, action) {
      state.cycleIdFromLocalStorage = action.payload;
    },
    removeLocalStorageDataOfGrothDayFromState(state, action) {
      state.growthDayFromLocalStorage = action.payload;
    },
    resetStateCoop(state) {
      state.growthDayFromLocalStorage = initialState.growthDayFromLocalStorage;
      state.getCoopById = { ...initialState.getCoopById };
      state.cyclesArray = initialState.cyclesArray;
      state.chosenCycle = initialState.chosenCycle;
      state.chosenCoop = initialState.chosenCoop;
      state.activeCycle = initialState.activeCycle;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoopByIdThunk.pending, extraReducers(pending, "getCoopById"))
      .addCase(
        getCoopByIdThunk.rejected,
        extraReducers(rejected, "getCoopById")
      )
      .addCase(
        getCoopByIdThunk.fulfilled,
        extraReducers(fulfilled, "getCoopById")
      )
      .addCase(startCycleThunk.pending, extraReducers(pending, "startingCycle"))
      .addCase(
        startCycleThunk.rejected,
        extraReducers(rejected, "startingCycle")
      )
      .addCase(
        startCycleThunk.fulfilled,
        extraReducers(fulfilled, "startingCycle")
      );
  },
});

export const {
  addGettedCoopToState,
  addCyclesToState,
  addChosenCoopsToState,
  addChosenCycleToState,
  addChosenCycleToStateWhenSettedFirstDay,
  addChosenCycleToStateOtherCase,
  setActiveCycle,
  setActiveCycleCountChicken,
  addNewCycleToCoop,
  replaceChangedCycleInCoop,
  setGrowthDayToCycle,
  removeLocalStorageDataOfFarmIdFromState,
  removeLocalStorageDataOfCoopIdFromState,
  removeLocalStorageDataOfCycleIdFromState,
  removeLocalStorageDataOfGrothDayFromState,
  resetStateCoop
} = coopsForUserSlice.actions;
export default coopsForUserSlice.reducer;
