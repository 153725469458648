import {
  getCoopForAdminById,
  updateCoopNameAndCoordinates,
  updateCoopSensors,
} from "./requests/adminCoops";
import {
  getFarmById,
  // createFarm,
  deleteFarmById,
  updateFarmName,
  updateFarmUsers,
  // createCoopForFarm,
} from "./requests/adminFarms";
import {
  getUsers,
  createUser,
  updateUser,
  updateUserRole,
} from "./requests/adminUsers";
import { createAlert, getAlerts, updateAlert } from "./requests/alerts";
import { attachCompanyToUser, createCompany, getCompanies } from "./requests/company";
import {
  attachUserCoop,
  getCoopById,
  startCycle,
  editCycle,
  stopCycle,
  updateDeath,
  updateGrowth,
} from "./requests/coopsForUsers";
import {
  getAlertsForUser,
  getDeathRateForUser,
  getGrowthRateForUser,
  getSensorsStatus,
  getVentilationData,
  getTempAndHumidData,
  getAverageData,
  getAQIData,
  getPHIData,
  getVentilationRecomendation,
  getWindAndFeels,
  getSound
} from "./requests/dashboardDataForUsers";
import { attachUserFarm, getFarms, getFarmsByCompany } from "./requests/farmsForUsers";
import { login, getRefreshToken, getUserData, getPossibListUser, registrationUser, putEditUser, deleteUser, getPossibListUserByCoop } from "./requests/userAuth";

export const api = {
  userAuth: {
    login,
    getRefreshToken,
    getUserData,
    getPossibListUser,
    getPossibListUserByCoop,
    registrationUser,
    putEditUser,
    deleteUser
  },
  alerts: {
    getAlerts,
    updateAlert,
    createAlert,
  },
  farms: {
    getFarms,
    attachUserFarm,
    getFarmsByCompany
  },
  coops: {
    getCoopById,
    startCycle,
    editCycle,
    stopCycle,
    updateDeath,
    updateGrowth,
    attachUserCoop
  },
  dashboard: {
    getAlertsForUser,
    getDeathRateForUser,
    getGrowthRateForUser,
    getSensorsStatus,
    getVentilationData,
    getTempAndHumidData,
    getAverageData,
    getAQIData,
    getPHIData,
    getVentilationRecomendation,
    getWindAndFeels,
    getSound
  },
  adminFarms: {
    getFarmById,
    // createFarm,
    updateFarmName,
    updateFarmUsers,
    deleteFarmById,
    // createCoopForFarm,
  },
  adminCoops: {
    getCoopForAdminById,
    updateCoopNameAndCoordinates,
    updateCoopSensors,
  },
  adminUsers: {
    getUsers,
    createUser,
    updateUser,
    updateUserRole,
  },
  company: {
    createCompany,
    getCompanies,
    attachCompanyToUser
  }
};
