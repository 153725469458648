import GrowthChart from "./growthChart/growthChart";
import styles from "./GrowthRate.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import GrowthDayInput from "../growthDayInput/GrowthDayInput";
import { changeUpdatingReloadGrowthStatus } from "../../store/reloadMarker";
import { updateGrowthThunk } from "../../store/coopsForUserSlice";
import { useDispatch } from "react-redux";

function GrowthRate() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  
  const reloadGrowthRate = () => {
    dispatch(changeUpdatingReloadGrowthStatus("start"));
  }
  return (
    <div className={styles.container}>
      <div className={styles.componentHeader}>
        <h3>{t("DEATH_RATE.SECOND_HEADER")}</h3>
        <div className={styles.headerButtons}>
          <div className={styles.growDay}>
          </div>
        </div>
      </div>
      <div className={styles.mainContent}>
        <div
          className={classNames(styles.graphSide, {
            [styles.paddingForRtl]: i18n.language === "he",
          })}
        >
          <GrowthDayInput fn={updateGrowthThunk} reloadChart={reloadGrowthRate}/>
          <div className={styles.chart}>
            <div className={styles.legend}>
              <div className={styles.item}>
                <div className={styles.greenCircle}></div>
                <span>{t("DEATH_RATE.PLANNED")}</span>
              </div>
              <div className={styles.item}>
                <div className={styles.blueCircle}></div>
                <span>{t("DEATH_RATE.CURRENT")}</span>
              </div>
            </div>
            <div className={styles.graph}>
              <GrowthChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrowthRate;
