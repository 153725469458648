import { createSlice } from "@reduxjs/toolkit";

// ['started', 'finished']
const initialState = {
  reloadStatus: "finish",
  reloadDeath: "finish",
  reloadGrowth: "finish"
};

const reloadStatus = createSlice({
  name: "updating",
  initialState,
  reducers: {
    changeUpdatingReloadStatus(state, action) {
      state.reloadStatus = action.payload;
    },
    changeUpdatingReloadDeathStatus(state, action) {
      state.reloadDeath = action.payload;
    },
    changeUpdatingReloadGrowthStatus(state, action) {
      state.reloadGrowth = action.payload;
    },
  },
});

export const {
  changeUpdatingReloadStatus,
  changeUpdatingReloadDeathStatus,
  changeUpdatingReloadGrowthStatus
} = reloadStatus.actions;
export default reloadStatus.reducer;
