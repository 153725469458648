import styles from "./DeathRate.module.scss";
import DeathChart from "./deathChart/deathChart";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
// import UpdateFormDeathRate from "./updateFormDeathRate/UpdateFormDeathRate";
import useIsDownloading from "../../hooks/useIsDownloading";
import GrowthDayInput from "../growthDayInput/GrowthDayInput";
import { updateDeathThunk } from "../../store/coopsForUserSlice";
import { useDispatch } from "react-redux";
import { changeUpdatingReloadDeathStatus } from "../../store/reloadMarker";

function DeathRate() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  
  const reloadDeathRate = () => {
    dispatch(changeUpdatingReloadDeathStatus("start"));
  }
  return (
    <div className={styles.container}>
      <div className={styles.componentHeader}>
        <h3>{t("DEATH_RATE.HEADER")}</h3>
        <div className={styles.headerButtons}>
          <div className={styles.growDay}>
          </div>
        </div>
      </div>
      <div className={styles.mainContent}>
        {/* <UpdateFormDeathRate /> */}
        <div
          className={classNames(styles.graphSide, {
            [styles.paddingForRtl]: i18n.language === "he",
          })}
        >
          <GrowthDayInput fn={updateDeathThunk} reloadChart={reloadDeathRate}/>
          <div className={styles.chart}>
            <div className={styles.legend}>
              <div className={styles.item}>
                <div className={styles.greenCircle}></div>
                <span>{t("DEATH_RATE.PLANNED")}</span>
              </div>
              <div className={styles.item}>
                <div className={styles.redCircle}></div>
                <span>{t("DEATH_RATE.CURRENT")}</span>
              </div>
            </div>
            <div className={styles.graph}>
              <DeathChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeathRate;
