import style from "./style.module.scss";

function Button({icon, label, orientationIcon = 'right', styleType, onClick = () => {}, className=''}) {
    return (
        <div onClick={onClick} className={[
            style.button,
            styleType === 'greenBorder' && style.greenBorder || '',
            styleType === 'red' && style.red || '',
            styleType === 'lr' && style.lr || '',
            icon && style.icon || '',
            className
            ].join(' ')}>
            {(icon && orientationIcon === 'left') && icon}
            {label}
            {(icon && orientationIcon === 'right') && icon}
        </div>
    );
}

export default Button;
